import { Props as SharedProps } from "./RefundTpp";
import { accountIdParam } from "../../CheckAvailability";
import {
  BookingEventDO_Split,
  BookingEventDO_Transfer,
  fullName,
  isReplaceEvent,
  isSplitEvent,
  isTransferEvent,
} from "data-model";
import { DateAndInitials } from "react-components";
import { FC, Fragment } from "react";
import { Link } from "react-router-dom";
import { bookingIdParam } from "../ManageBooking";

const whiteCell = "has-background-white padding-1";

type Props = Omit<SharedProps, "setBooking">;

const BookingHistory: FC<Props> = ({ booking, onClose }) => {
  if (!booking.events.length) {
    return <p className="is-marginless">No events found.</p>;
  }

  return (
    <div className="is-grid is-grid-template-columns-auto-1fr is-gap-1px has-background-gray has-border-gray">
      {booking.events.map((event) => (
        <Fragment key={event.id}>
          <span className={whiteCell}>
            <DateAndInitials
              dateIso={event.createdAt}
              loginId={event.loginId}
              showTime
            />
          </span>
          <span className={whiteCell}>
            {isReplaceEvent(event) ? (
              <>
                Account changed from{" "}
                <Link
                  onClick={onClose}
                  to={`/check-availability?${accountIdParam}=${event.fromAccount.id}`}
                >
                  {fullName(event.fromAccount)}
                </Link>{" "}
                to{" "}
                <Link
                  onClick={onClose}
                  to={`/check-availability?${accountIdParam}=${event.toAccount.id}`}
                >
                  {fullName(event.toAccount)}
                </Link>
                {requestedBy(event.requestedBy)}
              </>
            ) : isTransferEvent(event) ? (
              <>
                Booking transferred
                <FromToLink
                  bookingId={booking.id}
                  event={event}
                  onClick={onClose}
                />
                {requestedBy(event.requestedBy)}
              </>
            ) : isSplitEvent(event) ? (
              <>
                Booking split
                <FromToLink
                  bookingId={booking.id}
                  event={event}
                  onClick={onClose}
                />
                {requestedBy(event.requestedBy)}
              </>
            ) : null}
          </span>
        </Fragment>
      ))}
    </div>
  );
};

export { BookingHistory };

interface FromToLinkProps {
  bookingId: number;
  event: BookingEventDO_Split | BookingEventDO_Transfer;
  onClick: React.MouseEventHandler<HTMLAnchorElement>;
}

const FromToLink: FC<FromToLinkProps> = ({ bookingId, event, onClick }) => {
  const isSrcBooking = bookingId === event.fromBooking.id;
  const linkedBooking = isSrcBooking ? event.toBooking : event.fromBooking;

  return (
    <>
      {" "}
      {isSrcBooking ? "to" : "from"}{" "}
      <Link
        onClick={onClick}
        to={`/manage-booking?${bookingIdParam}=${linkedBooking.id}&${accountIdParam}=${linkedBooking.owner.id}`}
      >
        {linkedBooking?.number}
      </Link>
    </>
  );
};

const requestedBy = (name: string) => `; Requested by ${name}`;
