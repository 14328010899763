import { Props as SharedProps } from "./RefundTpp";
import { bookingIdParam } from "../ManageBooking";
import { accountIdParam } from "../../CheckAvailability";
import { splitRooms } from "../../../api";
import { ModalOverlay } from "../../../components";
import {
  adjustmentFees,
  arrOfSize,
  depositFee,
  finalPaymentFee,
  fullNameOrPlaceholder,
  isDepositOverdue,
  isFeeOwed,
  isFinalPaymentOverdue,
  isGuestAccountOwner,
  roomAbbrCapitalized,
} from "data-model";
import {
  Checkbox,
  ErrorMessage,
  Input,
  PaymentIndicator,
  SVG,
  useErrors,
} from "react-components";
import { CSSProperties, FC, useState } from "react";
import { useNavigate } from "react-router-dom";

const idPrefix = "split-rooms";

const style5Rows2Cols: CSSProperties = {
  gridTemplateColumns: "repeat(2, 225px)",
  gridTemplateRows: "repeat(5, 1fr)", // DANNO_MAX_ROOMS / 2 cols
};
const style4Rows: CSSProperties = { gridTemplateRows: "repeat(4, 1fr)" }; // Quad

type Props = Omit<SharedProps, "setBooking">;

const SplitRooms: FC<Props> = ({ booking, onClose }) => {
  const navigate = useNavigate();

  const [errors, catchErrors] = useErrors();
  const [isLoading, setIsLoading] = useState(false);

  const [roomIndexes, setRoomIndexes] = useState<number[]>([]); // to split
  const [requestedBy, setRequestedBy] = useState("");
  const [reconfirm, setReconfirm] = useState(true);

  const [isOverlayed, setIsOverlayed] = useState(false);

  let posIdx = -1;
  const depositOverdue = isDepositOverdue(booking);
  const finalOverdue = isFinalPaymentOverdue(booking);

  const handleRoomSplit = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, dataset } = e.currentTarget;
    const roomIdx = Number(dataset.roomIdx);

    if (checked) {
      setRoomIndexes([...roomIndexes, roomIdx]);
    } else {
      setRoomIndexes(roomIndexes.filter((i) => i !== roomIdx));
    }
  };

  const handleSplitSubmit = () => {
    setIsLoading(true);
    catchErrors(
      async () => {
        const splitId = await splitRooms(booking.id, {
          roomIndexes,
          requestedBy: requestedBy.trim(),
          reconfirm,
        });

        onClose();

        navigate(
          `/manage-booking?${bookingIdParam}=${splitId}&${accountIdParam}=${booking.owner.id}`
        );
      },
      () => setIsLoading(false)
    );
  };

  const handleOverlayToggle = () => {
    setIsOverlayed(!isOverlayed);
    setReconfirm(true);
  };

  return (
    <>
      <div
        className="is-grid is-column-gap-2 is-row-gap-2 "
        style={style5Rows2Cols}
      >
        {booking.roomGuests.map((guestsInRoom, roomIdx) => (
          <div
            key={roomIdx}
            className="padding-2 has-border-gray is-rounded-small"
          >
            <div className="is-flex is-justify-content-space-between margin-bottom-1">
              <h2 className="is-size-3 is-marginless">
                Room {roomIdx + 1} &ndash; {roomAbbrCapitalized(guestsInRoom)}
              </h2>
              <Checkbox
                id={`${idPrefix}-${roomIdx}`}
                labelClassName="is-order-neg-1 margin-right-2 margin-left-0"
                data-room-idx={roomIdx}
                checked={roomIndexes.includes(roomIdx)}
                onChange={handleRoomSplit}
                disabled={
                  !roomIndexes.includes(roomIdx) &&
                  roomIndexes.length === booking.roomGuests.length - 1
                }
              >
                <strong>Split</strong>
              </Checkbox>
            </div>

            <ul
              className="has-list-style-type-none is-paddingless is-marginless is-grid"
              style={style4Rows}
            >
              {arrOfSize(guestsInRoom).map((_, guestIdx) => {
                ++posIdx;
                const guest = booking.guests[posIdx];
                const isOwner = isGuestAccountOwner(
                  booking.guests,
                  booking.owner,
                  posIdx
                );

                return (
                  <li
                    key={guestIdx}
                    // min-width: 0; prevents grid items from over-extending https://stackoverflow.com/a/43312314
                    className="is-flex is-align-items-baseline is-column-gap-1 is-min-width-0"
                  >
                    <span className="has-ellipsis">
                      {fullNameOrPlaceholder(
                        booking.guests,
                        booking.owner,
                        posIdx
                      )}
                    </span>
                    {isOwner && (
                      <SVG
                        path="/site/icon/person-black"
                        alt="Person"
                        height={12}
                      />
                    )}

                    <span className="is-flex is-align-items-center margin-left-auto">
                      <PaymentIndicator
                        isAnnulled={false}
                        isOverdue={depositOverdue}
                        numOfPax={1}
                        numOfPayments={isFeeOwed(depositFee(guest)) ? 0 : 1}
                        size="medium"
                      />
                      <span className="margin-left-1" />
                      <PaymentIndicator
                        isAnnulled={false}
                        isOverdue={finalOverdue}
                        numOfPax={1}
                        numOfPayments={
                          isFeeOwed(finalPaymentFee(guest)) ||
                          adjustmentFees(guest).some(isFeeOwed)
                            ? 0
                            : 1
                        }
                        size="medium"
                      />
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        ))}
      </div>

      <div className="is-flex is-align-items-center is-column-gap-2 margin-top-2">
        <label
          htmlFor={`${idPrefix}-requested-by`}
          className="is-flex-shrink-0"
        >
          Requested By:
        </label>
        <Input
          id={`${idPrefix}-requested-by`}
          value={requestedBy}
          onChange={(e) => setRequestedBy(e.currentTarget.value)}
          placeholder="Name Required"
        />
        <button
          className="button is-light-blue padding-x-6"
          onClick={handleOverlayToggle}
          disabled={
            roomIndexes.length === 0 || !requestedBy.trim() || isLoading
          }
        >
          Split
        </button>
      </div>

      <ErrorMessage errors={errors} className="margin-top-2 margin-bottom-0" />

      {isOverlayed && (
        <ModalOverlay onClose={handleOverlayToggle} style={{ maxWidth: 350 }}>
          <h2 className="margin-top-0 has-text-centered">Split?</h2>

          <Checkbox
            id={`${idPrefix}-reconfirm`}
            checked={reconfirm}
            onChange={() => setReconfirm(!reconfirm)}
            disabled={isLoading}
          >
            Send a Reconfirmation of change to original booking.
          </Checkbox>

          <p>
            After splitting, please reassign the account owner of the new
            booking.
          </p>

          <div className="is-flex is-column-gap-2">
            <button className="button" onClick={handleOverlayToggle}>
              Cancel
            </button>
            <button
              className="button is-light-blue is-flex-1"
              onClick={handleSplitSubmit}
              disabled={isLoading}
            >
              Split and Go to New Booking
            </button>
          </div>
        </ModalOverlay>
      )}
    </>
  );
};

export { SplitRooms };
