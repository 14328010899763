import { Props as SharedProps } from "./RefundTpp";
import {
  AccountFinder,
  AccountInfo,
  AccountNotes,
  BookingExplorer,
  SetAccount,
  accountIdParam,
} from "../../CheckAvailability";
import { replaceAccount } from "../../../api";
import { ModalOverlay } from "../../../components";
import { AccountDO } from "data-model";
import { Checkbox, ErrorMessage, Input, useErrors } from "react-components";
import { CSSProperties, FC, useState } from "react";

const idPrefix = "replace-account";
const rightPanelStyle: CSSProperties = { width: 450, minHeight: 805 };
const listStyle: CSSProperties = { height: 315 };

interface Props extends SharedProps {
  searchParams: URLSearchParams;
  setSearchParams: (searchParams: URLSearchParams) => void;
}

const ReplaceAccount: FC<Props> = ({
  booking,
  onClose,
  searchParams,
  setBooking,
  setSearchParams,
}) => {
  const [account, setAccount] = useState<AccountDO | null>(null);
  const [requestedBy, setRequestedBy] = useState("");
  const [isOverlayed, setIsOverlayed] = useState(false);

  const [emailOldOwner, setEmailOldOwner] = useState(true);
  const [emailNewOwner, setEmailNewOwner] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [errors, catchErrors] = useErrors();

  const handleOverlayToggle = () => {
    setIsOverlayed(!isOverlayed);
    setEmailOldOwner(true);
    setEmailNewOwner(true);
  };

  const handleReplace = () => {
    setIsLoading(true);
    catchErrors(
      async () => {
        if (!account) throw new Error("unreachable");

        const { event, confirmation } = await replaceAccount(booking.id, {
          id: account.id,
          requestedBy: requestedBy.trim(),
          emailOldOwner,
          emailNewOwner,
        });

        setBooking((booking) => ({
          ...booking,
          owner: account,
          events: [...booking.events, event],
          confirmations: confirmation
            ? [...booking.confirmations, confirmation]
            : booking.confirmations,
        }));

        onClose();

        searchParams.set(accountIdParam, `${account.id}`);
        setSearchParams(searchParams);
      },
      () => setIsLoading(false)
    );
  };

  return (
    <div className="is-flex is-column-gap-5">
      <div>
        <h2 className="margin-top-0">Current:</h2>
        <AccountInfo
          account={booking.owner}
          className="is-row-gap-1"
          withName
        />
      </div>

      <div style={rightPanelStyle} className="is-flex is-flex-direction-column">
        <h2 className="margin-top-0">Find or Create New Account:</h2>

        {account ? (
          <div className="is-flex is-flex-direction-column">
            <div className="is-flex is-align-items-flex-start">
              <AccountInfo account={account} className="is-flex-1" withName />
              <button
                className="button is-ghost is-link is-paddingless"
                disabled={isLoading}
                onClick={() => setAccount(null)}
              >
                Clear
              </button>
            </div>

            <div className="has-border-x-gray has-border-bottom-gray margin-y-3">
              <AccountNotes
                account={account}
                isLocked
                setAccount={setAccount as SetAccount}
              />
            </div>

            <BookingExplorer
              bookings={account.bookings}
              hideControls
              listStyle={listStyle}
              setAccount={setAccount as SetAccount}
            />
          </div>
        ) : (
          <AccountFinder onAccountApply={setAccount} />
        )}

        <div className="is-flex is-align-items-center margin-top-auto">
          <Input
            className="is-width-auto"
            onChange={(e) => setRequestedBy(e.currentTarget.value)}
            placeholder="Requested By"
            size={12}
            value={requestedBy}
          />
          <span className="margin-left-1 is-flex-1">(shows in email)</span>
          <button
            className="button is-blue padding-x-4"
            disabled={
              !account ||
              account.id === booking.owner.id ||
              !requestedBy.trim() ||
              isLoading
            }
            onClick={handleOverlayToggle}
          >
            Replace
          </button>
        </div>

        <ErrorMessage
          errors={errors}
          className="margin-top-2 margin-bottom-0"
        />

        {isOverlayed && (
          <ModalOverlay onClose={handleOverlayToggle} style={{ maxWidth: 320 }}>
            <h2 className="margin-top-0 has-text-centered">Replace Owner?</h2>

            <Checkbox
              id={`${idPrefix}-email-old-owner`}
              checked={emailOldOwner}
              onChange={() => setEmailOldOwner(!emailOldOwner)}
              parentClassName="margin-bottom-1"
              disabled={isLoading}
            >
              Send an email to old booking owner
            </Checkbox>

            <Checkbox
              id={`${idPrefix}-email-new-owner`}
              checked={emailNewOwner}
              onChange={() => setEmailNewOwner(!emailNewOwner)}
              parentClassName="margin-bottom-4"
              disabled={isLoading}
            >
              Send a Reconfirmation to new booking owner
            </Checkbox>

            <div className="is-flex is-column-gap-2">
              <button className="button" onClick={handleOverlayToggle}>
                Cancel
              </button>
              <button
                className="button is-light-blue is-flex-1"
                onClick={handleReplace}
                disabled={isLoading}
              >
                Replace
              </button>
            </div>
          </ModalOverlay>
        )}
      </div>
    </div>
  );
};

export { ReplaceAccount, Props as PropsWithSearch };
