import type { CSSProperties, FC, PropsWithChildren } from "react";

const overlayStyle = { background: "rgba(10, 10, 10, 0.55)" };

interface Props extends PropsWithChildren {
  onClose: () => void;
  style?: CSSProperties;
}

// Note: clicking outside the modal still closes the modal (and the overlay).
const ModalOverlay: FC<Props> = ({ children, onClose, style }) => {
  return (
    <div className="is-absolute is-top-0 is-left-0 is-right-0 is-bottom-0 is-flex is-align-items-center is-justify-content-center">
      <div
        style={overlayStyle}
        className="is-absolute is-fullwidth is-fullheight"
        onClick={onClose}
      />
      <div
        className="is-relative has-background-white padding-x-2 padding-y-4 is-rounded"
        style={style}
      >
        {children}
      </div>
    </div>
  );
};

export { ModalOverlay };
